
$(document).ready(function() {

    /*Menu responsive*/
    $('#menu-oculto').slicknav({
        label: '',
        duration: 1000,
        easingOpen: "easeOutBounce", //available with jQuery UI
        prependTo: '#menu-oculto-container',
        allowParentLinks: true,
        closeOnClick: true,
    });



    //Política de proteccion de datos (formulario de contacto)
   
        $('#politica_desplegable').click(function(event) {
            $('#politica_oculto').toggle("slow");
            event.preventDefault();
        });


        $('#politica_desplegable2').click(function(event) {
            $('#politica_oculto2').toggle("slow");
            event.preventDefault();
        });
     


});

$(document).ready(function($) {
    $("#oferta option[value='SeleccioneOferta']").attr("selected",true);
    var formTrabaja = $('#form-trabaja');
    var formMessages = $('#form-trabaja-resultado');

    //Validación formulario trabaja con nosotros

    $.validate({
        modules : 'logic file',
        form : '#form-trabaja',
        lang: 'es',
    });


    $(formTrabaja).submit(function(event) {
        event.preventDefault();

        $.post(
            $(formTrabaja).attr('action'),
            $(formTrabaja).serialize()
        )
        .done(function(response){
            $(formMessages).html('');
            $(formMessages).removeClass('error');
            $(formMessages).addClass('success');
            $('.formulario-trabaja').hide();
            // Set the message text.
            $(formMessages).html(response.mensaje);
            console.log(response.mensaje);
        })
        .fail(function(data){
            $(formMessages).html('');
            $(formMessages).removeClass('success');
            $(formMessages).addClass('error');

            errorsHtml = '<div class="alert alert-danger"><ul>';
            $.each( data.responseJSON, function( key, value ) {
                errorsHtml += '<li>' + value + '</li>'; //showing only the first error.
            });
            errorsHtml += '</ul></div>';
            $(formMessages).html(errorsHtml);
            console.log(data.responseJSON);
        });

    });


    var formContacta = $('#form-contacto');
    var formMessagesContacta = $('#form-contacto-resultado');
    $(formContacta).submit(function(event) {
        event.preventDefault();

        $.post(
            $(formContacta).attr('action'),
            $(formContacta).serialize()
            )
            .done(function(response){
                $(formMessagesContacta).html('');
                $(formMessagesContacta).removeClass('error');
                $(formMessagesContacta).addClass('success');
                $(formContacta).hide();
                // Set the message text.
                $(formMessagesContacta).html(response.mensaje);
            })
            .fail(function(data){
                $(formMessagesContacta).html('');
                $(formMessagesContacta).removeClass('success');
                $(formMessagesContacta).addClass('error');

                errorsHtml = '<div class="alert alert-danger"><ul>';
                $.each( data.responseJSON, function( key, value ) {
                    errorsHtml += '<li>' + value + '</li>'; //showing only the first error.
                });
                errorsHtml += '</ul></div>';
                $(formMessagesContacta).html(errorsHtml);
            });
    });


});



